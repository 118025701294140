<template>

  <b-card>
    <!-- <b-row>
      <b-col cols="12" md="12" class="mb-1">
        <b-form-input
          id="filterInput"
          v-model="filter"
          class="d-inline-block mr-1"
          placeholder="Suchen..."
      />
      :filter="filter" -- drei Zeilen bei folgender Table nach :fields einfügen - Verhalten ist komisch, row ist wohl irgendwie falsch gesetzt - to be optimized once...
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      </b-col>
    </b-row> -->

    <b-table
      ref="selectableTable"
      selectable
      select-mode="single"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      primary-key="BelegID"
      selected-variant="primary"
      :fields="fields"
      @row-selected="showBelegTable"
    >
      <template #row-details>
        <div class="d-flex align-items-center justify-content-end mb-1">
          <b-button
            v-show="currRow.AnzahlBelege > 0"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="addBeleg"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span class="align-middle">Neuen Beleg</span>
          </b-button>
        </div>

        <b-table
          ref="selectableTableX"
          selectable
          select-mode="single"
          striped
          hover
          responsive
          class="position-relative"
          :per-page="perPage"
          :items="itemsDosBeleg"
          primary-key="DosBelegID"
          selected-variant="primary"
          :fields="dosFields"
          @row-selected="showBeleg"
        >
          <template #row-details="row">
            <b-card>
              <b-row>
                <b-col
                  md="6"
                >
                  <b-form-group
                    label="Beleg"
                    label-for="Beleg"
                  >
                    <b-form-input
                      v-model="row.item.Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="3"
                >
                  <b-form-group
                    label="Von wem"
                    label-for="Von"
                  >
                    <v-select
                      v-model="row.item.Von"
                      :options="vonWem"
                      :reduce="val => val.value"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="3"
                  class="text-right"
                >
                  <b-form-group
                    label="Für Gericht"
                    label-for="Gericht"
                  >
                    <b-form-checkbox
                      v-model="row.item.IstGericht"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="6 mb-1"
                >
                  <b-form-file
                    v-model="file"
                    accept=".pdf, .docx, .xlsx, .jpeg, .png, .zip"
                    placeholder="Beleg auswählen oder hier ablegen..."
                    drop-placeholder="Datei hier ablegen..."
                  />
                  <small
                    v-show="file !== null && file.size > maxFileSize"
                    class="text-danger"
                  >{{ $g.browserLocale === 'fr' ? 'La grandeur du ficher ne doit pas depasser 500 KB!' : 'Die Datei darf nicht grösser als 500 KB sein!' }}</small>
                </b-col>
                <b-col
                  md="6 text-right"
                >
                  <b-button
                    variant="primary"
                    :disabled="currRowBeleg.Mime === null || currRowBeleg.Mime === ''"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="download"
                  >
                    Herunterladen
                  </b-button>
                </b-col>
                <b-col
                  md="12"
                >
                  <b-form-group
                    label="Bemerkung"
                    label-for="Bemerkung"
                  >
                    <b-form-textarea
                      v-model="row.item.Bemerkung"
                      rows="2"
                      max-rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                :disabled="! (file === null || (file !== null && file.size <= maxFileSize))"
                @click="speichern(row.item)"
              >
                Speichern
              </b-button>
            </b-card>
          </template>
        </b-table>

      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Pro Seite"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <b-button
        variant="primary"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="anzahlBelegeTotal === 0"
        @click="downloadAll"
      >
        Alle Belege herunterladen
      </b-button>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BButton, BCard, BTable, BRow, BCol, BFormGroup, BFormSelect, BFormInput, BFormCheckbox, BFormTextarea, BFormFile, BPagination, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import db from '@/service/beleg'

export default {
  components: {
    BButton,
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    vSelect,
    BFormTextarea,
    BFormFile,
    BPagination,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  setup() {
    const vonWem = [
      { label: 'Familie', value: 1 },
      { label: 'Paar', value: 2 },
      { label: 'Frau', value: 3 },
      { label: 'Mann', value: 4 },
      { label: 'Kind', value: 5 },
    ]
    const tasks = []
    return {
      vonWem,
      tasks,
    }
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      currRow: null,
      currRowBeleg: null,
      file: null,
      maxFileSize: 500000,
      filter: null,
      filterOn: [],
      fields: [
        { key: 'Name', label: 'Name', sortable: true },
        { key: 'Beschrieb', label: 'Beschrieb' },
      ],
      dosFields: [
        { key: 'Name', label: 'Beleg' },
        { key: 'DateiName', label: 'Datei' },
        { key: 'Datum', label: 'Datum' },
      ],
      /* eslint-disable global-require */
      items: null,
      itemsDosBeleg: null,
      tblRow: null,
      selected: [],
      anzahlBelegeTotal: 0,
    }
  },
  async created() {
    const r = await db.getBeleg(this.$root.currDosID, this.$g.user.BenSprache)
    if (r.data.length > 0) {
      for (let i = 0; i < r.data.length; i += 1) r.data[i]._showDetails = false // eslint-disable-line
      this.items = r.data
      this.totalRows = this.items.length
      this.anzahlBelegeTotal = this.items[0].AnzahlBelegeTotal
    }
  },
  methods: {
    async showBelegTable(row) {
      if (row.length === 1) {
        if (this.currRow !== null && this.currRow.BelegID !== row[0].BelegID) this.currRow._showDetails = false // eslint-disable-line
        const r = await db.getDossierBeleg(0, this.$root.currDosID, row[0].BelegID, this.$g.user.BenSprache)
        if (r.data.length > 0) {
          this.currRow = row[0]
          for (let i = 0; i < r.data.length; i += 1) r.data[i]._showDetails = false // eslint-disable-line
          this.itemsDosBeleg = r.data
          // this.totalRows = this.itemsDosBeleg.length
          this.anzahlBelegeTotal = this.itemsDosBeleg.length
        }
        row[0]._showDetails = !row[0]._showDetails  // eslint-disable-line
      } else this.currRow._showDetails = !this.currRow._showDetails  // eslint-disable-line
    },
    showBeleg(row) {
      if (row.length === 1) this.currRowBeleg = row[0]
      this.currRowBeleg._showDetails = !this.currRowBeleg._showDetails  // eslint-disable-line
    },
    async speichern(item) {
      const r = await db.putDossierBeleg(item)
      if (this.currRowBeleg.DosBelegID === 0) this.currRowBeleg.DosBelegID = r.data[0]
      if (this.file === null) return
      await db.postBelegDatei(this.$root.currDosID, this.file, this.currRowBeleg.DosBelegID)
      for (let i = 0; i < this.itemsDosBeleg.length; i += 1) {
        if (this.itemsDosBeleg[i].DosBelegID === this.currRowBeleg.DosBelegID) {
          this.itemsDosBeleg[i].DateiName = this.file.name
          if (this.currRowBeleg.DosBelegID === 0) this.itemsDosBeleg[i].DosBelegID = r.data[0]
          break
        }
      }
      if (this.anzahlBelegeTotal === 0) this.anzahlBelegeTotal = 1
      this.$root.showToast('Beleg speichern', 'Beleg wurde erfolgreich gespeichert!')
    },
    addBeleg() {
      for (let i = 0; i < this.itemsDosBeleg.length; i += 1) this.$refs.selectableTable.unselectRow(i)
      const np = JSON.parse(JSON.stringify(this.itemsDosBeleg)).slice(0, 1)
      np[0].DosBelegID = 0
      np[0].Datum = null
      np[0].Name = this.currRow.Name
      np[0].DateiName = ''
      np[0].Groesse = 0
      np[0].Bemerkung = ''
      np[0]._showDetails = true // eslint-disable-line
      this.itemsDosBeleg.push(np[0])
      this.totalRows = this.itemsDosBeleg.length
    },
    async download() {
      const r = await db.getBelegDatei(this.$root.currDosID, this.currRowBeleg.DosBelegID, this.currRowBeleg.Mime)
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.download = this.currRowBeleg.DateiName
      // fileLink.setAttribute('download', this.currRowBeleg.DateiName)
      document.body.appendChild(fileLink)

      fileLink.click()

      document.body.removeChild(fileLink)
      URL.revokeObjectURL(fileLink.href)
    },
    async downloadAll() {
      const r = await db.getBelegZip(this.$root.currDosID)
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.download = 'Belege.zip'
      // fileLink.setAttribute('downloadAll', 'Belege.zip')
      document.body.appendChild(fileLink)
      fileLink.click()

      document.body.removeChild(fileLink)
      URL.revokeObjectURL(fileLink.href)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
